@import 'global-vars.css';

html {
    scroll-behavior: var(--scrollBehavior);
}

:global body {
    padding: var(--bodyPadding);
    margin: var(--bodyMargin);
    background-color: var(--bodyBackgroundColor);
    font-family: var(--fontFamily);
}

.paragraph {
    margin: var(--paragraphMargin);
}
